import { useState } from 'react';
import '../css/viewWithVisualization.css';

export default function ViewWithVisualization({photo, altPhoto, visualization, altVisualization, id, view}) {
    const [visualizationVisible, setVisualizationVisible] = useState(false);

    function showVisualization(id) {
        document.getElementById(id).classList.toggle('showVisualization');
        setVisualizationVisible(prevState => !prevState);
    };

  return (
    <>  
        <div className='absolute full-height full-width flex column justify-content-center'>
            <div className="flex justify-content-center">
                <div className='flex column justify-content-center'>
                    <div className='images-wrapper images-width relative'>
                        <img id={id} className='landscape-photo images-width absolute cursor-pointer' src={photo} alt={altPhoto}/>
                        <img className='landscape-photo images-width visualization absolute' src={visualization} alt={altVisualization}/>
                    </div>
                    <div className='flex justify-content-center show-visualization-button-mobile'>
                        <button className='show-visualization-button show-visualization-button-font-size cursor-pointer' onClick={() => showVisualization(id)}>
                            <p>{visualizationVisible ? "Verberg" : "Toon"} afgewerkt voorbeeld</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className='flex full-height column justify-content-flex-end'>
            <div className='flex justify-content-center show-visualization-button-desktop'>
                <button className='show-visualization-button show-visualization-button-font-size cursor-pointer' onClick={() => showVisualization(id)}>
                    <p>{visualizationVisible ? "Verberg" : "Toon"} afgewerkt voorbeeld</p>
                </button>
            </div>
            <div className='view-place-tekst-wrapper text-align-center'>
                <p className='view-place-tekst view-place-tekst-font-size'>{view}</p>
            </div>
        </div>
    </>
  )
};
