import {GroundFloorFloorPlan, FirstFloorFloorPlan, SecondFloorFloorPlan} from '../assets/images';
import Eye from './eye';
import '../css/floorPlans.css';

export default function FloorPlans({changeViewsPopupState, viewsPopupOpen, swiper}) {

  return (
    <>
        <div className="flex column floor-plans-wrapper relative">
            <div className="flex section-wrapper">
                <div className="relative">
                    <Eye id={"eye-1"} index={0} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"ground-floor"} title={"Overdekt Terras"}/>
                    <Eye id={"eye-2"} index={1} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"ground-floor"} title={"Keuken - Eetruimte"}/>
                    <img id="ground-floor-plan" className="floorplan" src={GroundFloorFloorPlan} alt="Gelijkvloers"/>
                </div>
                <div id='section-title-wrapper-1' className="section-title-wrapper section-title-wrapper-width">
                    <div className='section-title-mobile'>
                        <p id="ground-floor" className="section-title section-title-font-size section-title-line-thickness">Gelijkvloers</p>
                    </div>
                </div>
            </div>
            <div className="flex section-wrapper">
                <div id='section-title-wrapper-2' className="section-title-wrapper section-title-wrapper-width">
                    <div className='section-title-mobile'>
                        <p id="first-floor" className="section-title section-title-font-size section-title-line-thickness">1ste verdiep</p>
                    </div>
                </div>
                <div className="relative">
                    <Eye id={"eye-3"} index={2} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"first-floor"} title={"Living"}/>
                    <Eye id={"eye-4"} index={3} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"first-floor"} title={"Living"}/>
                    <Eye id={"eye-5"} index={4} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"first-floor"} title={"Badkamer"}/>
                    <Eye id={"eye-6"} index={5} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"first-floor"} title={"Badkamer"}/>
                    <img id="first-floor-plan" className="floorplan" src={FirstFloorFloorPlan} alt="1ste verdiep"/>
                </div>
            </div>
            <div className="flex section-wrapper">
                <div className="relative">
                    <Eye id={"eye-7"} index={6} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"second-floor"} title={"Grote slaapkamer"}/>
                    <Eye id={"eye-8"} index={7} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"second-floor"} title={"Grote slaapkamer"}/>
                    <Eye id={"eye-9"} index={8} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"second-floor"} title={"kinderkamer"}/>
                    <Eye id={"eye-10"} index={9} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"second-floor"} title={"kinderkamer"}/>
                    <Eye id={"eye-11"} index={10} swiper={swiper} changeViewsPopupState={changeViewsPopupState} section={"second-floor"} title={"kinderkamer"}/>
                    <img id="second-floor-plan" className="floorplan" src={SecondFloorFloorPlan} alt="2de verdiep"/>
                </div>
                <div id='section-title-wrapper-3' className="section-title-wrapper section-title-wrapper-width">
                    <div className='section-title-mobile'>
                        <p id="second-floor" className="section-title section-title-font-size section-title-line-thickness">2de verdiep</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
