export const giveConsentToTrack = () => {
    window.fbq('consent', 'grant');
    window.localStorage.setItem("userGaveConsent", "true");
};

export const trackPageView = () => {
    if ((window.sessionStorage.getItem("pageView") === null) && (window.localStorage.getItem("userGaveConsent")) === "true") { // If the user gave his consent to get tracked and has not been on the page in this session yet
        window.fbq('track', 'PageView');
        window.sessionStorage.setItem("pageView", "true");
    };
};

export const trackReachedTheBottom = () => {
    if ((window.sessionStorage.getItem("reachedBottom") === null) && (window.localStorage.getItem("userGaveConsent")) === "true") { // If the user gave his consent to get tracked and hasnt reach the bottom of the page this session
        window.fbq('trackCustom', 'reachedTheBottom');
        window.sessionStorage.setItem("reachedBottom", "true");
    };
};

export const trackViews = (viewID) => {
    let view = "";
    if (viewID === 0) {
        view = "sawView1";
    } else if (viewID === 1) {
        view = "sawView2";
    } else if  (viewID === 2) {
        view = "sawView3";
    } else if (viewID === 3) {
        view = "sawView4";
    } else if (viewID === 4) {
        view = "sawView5";
    } else if (viewID === 5) {
        view = "sawView6";
    } else if (viewID === 6) {
        view = "sawView7";
    } else if (viewID === 7) {
        view = "sawView8";
    } else if (viewID === 8) {
        view = "sawView9";
    } else if (viewID === 9) {
        view = "sawView10";
    } else if (viewID === 10) {
        view = "sawView11";
    };
    
    
    if ((window.sessionStorage.getItem(view) === null) && (window.localStorage.getItem("userGaveConsent")) === "true") { // If the user gave his consent to get tracked and id he has not yet seen this view this session
        window.fbq('trackCustom', view);
        window.sessionStorage.setItem(view, "true");
    };
};

export const trackContactAttempt = () => {
    if ((window.sessionStorage.getItem("contactAttempt") === null) && (window.localStorage.getItem("userGaveConsent")) === "true") { // If the user gave his consent to get tracked and If the user has not yet tried to send a message 
        window.fbq('track', 'Contact');
        window.sessionStorage.setItem("contactAttempt", "true");
    };
};





