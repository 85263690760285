import {TelefoonIcon, EmailIconGold, CheckMark, Garden} from '../assets/images';
import {trackContactAttempt} from '../trackingUtils.js';
import '../css/contact.css';

export default function Contact() {

    function changeMessage(intention) {
        if (intention === "info") {
            document.getElementById("checkMarkInfo").style.visibility = 'visible';
            document.getElementById("checkMarkVisit").style.visibility = 'hidden';
            document.getElementById("message").value = "Ik zou graag meer info krijgen over uw casco-woning. Bedankt";
        } else if (intention === "visit") {
            document.getElementById("checkMarkInfo").style.visibility = 'hidden';
            document.getElementById("checkMarkVisit").style.visibility = 'visible';
            document.getElementById("message").value = "Ik zou graag een bezoek inplannen om de casco-woning te komen bezichtigen. Bedankt";
        };
    };


  return (
    <>
        <div className='flex column contact-wrapper contact-wrapper-padding-inline'>
            <div className='text-align-center contact-title'>
                <p id="contact">Vragen of interesse?</p>
            </div>
            <div className='flex contact-gap-desktop'>
                <div className='image-wrapper flex'>
                    <img className='head-shot' src={Garden} alt="Tuin"/>
                </div>
                <div className='flex column flex-grow'>
                    <div className='flex contact-info-wrapper'>
                        <div className='flex gap-5'>
                            <img src={TelefoonIcon} alt="telefoon-icon" width='20px' height='20px'/>
                            <div className='contact-info-text flex column justify-content-center'>
                                <p>32 470 82 63 53</p>
                            </div>
                        </div>
                        <a href="mailto:sander.milloen@milcode.be" className='flex gap-5'>
                            <img src={EmailIconGold} alt="telefoon-icon" width='20px' height='20px'/>
                            <div className='contact-info-text flex column justify-content-center'>
                                <p>sander.milloen@milcode.be</p>
                            </div>
                        </a>
                    </div>
                    <form className='contact-form' action="https://formsubmit.co/bb9e84c5a0d7b509ae287fd8d0ad096c " method="POST">
                        <div className='flex column form-gap'>
                            <div className='flex form-elements-gap'>
                                <div className='flex column form-element-wrapper flex-grow'>
                                    <label className='formLabel' htmlFor="firstName">Voornaam</label>
                                    <input id='firstName' className='form-input' type="text" name='Voornaam' required/>
                                </div>
                                <div className='flex column form-element-wrapper flex-grow'>
                                    <label className='formLabel' htmlFor="lastName">Achternaam</label>
                                    <input id='lastName' className='form-input' type="text" name='Achternaam' required/>
                                </div>
                            </div>
                            <div className='flex form-elements-gap'>
                                <div className='flex column form-element-wrapper flex-grow'>
                                    <label className='formLabel' htmlFor="E-mail">E-mail</label>
                                    <input id='E-mail' className='form-input' type="email" name='E-mail' required/>
                                </div>
                                <div className='flex column form-element-wrapper flex-grow'>
                                    <label className='formLabel' htmlFor="phone-number">Telefoonnummer <span className='optional'>(optioneel)</span></label>
                                    <input id='phone-number' className='form-input' type="text" name='Telefoonnummer'/>
                                </div>
                            </div>
                            <div className='flex column gap-15'>
                                <div className='formLabel'>
                                    <p>Ik zou graag</p>
                                </div>
                                <div className='flex info-options-wrapper'>
                                    <label htmlFor="want-more-info" className='flex gap-10 cursor-pointer'>
                                        <div className='check-box flex justify-content-center'>
                                            <img id='checkMarkInfo' className='checkMark' src={CheckMark} alt="checkMark" width='20px'/>
                                        </div>
                                        <div className='flex column justify-content-center formLabel'>
                                            <p>Meer info krijgen</p>
                                        </div>
                                    </label>
                                    <label htmlFor="want-to-plan-vistit" className='flex gap-10 cursor-pointer'>
                                        <div className='check-box flex justify-content-center'>
                                            <img id='checkMarkVisit' className='checkMark' src={CheckMark} alt="checkMark" width='20px'/>
                                        </div>
                                        <div className='flex column justify-content-center formLabel'>
                                            <p>Een bezoek plannen</p>
                                        </div>
                                    </label>

                                    <input id='want-more-info' type="radio" name='intent-of-the-message' value="info" onChange={e => changeMessage(e.target.value)} />
                                    <input id='want-to-plan-vistit' type="radio" name='intent-of-the-message' value="visit" onChange={e => changeMessage(e.target.value)}/>
                                </div>
                            </div>
                            <div className='flex column gap-10'>
                                <label htmlFor="message" className='formLabel'>Jouw bericht</label>
                                <textarea id='message' name="message" defaultValue="Ik ben geïnteresseerd in uw casco-woning. Kunt u me alvast wat meer informatie geven om een eventueel bezoek in te plannen? Hartelijk dank." required></textarea>
                            </div>
                            <div className='position-submit-button-mobile'>
                                <button type="submit" className='submit-button cursor-pointer' onClick={() => trackContactAttempt()}>Verstuur bericht</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}
