import {EyeIcon} from '../assets/images';
import {trackViews} from '../trackingUtils.js';
import '../css/eye.css';

export default function Eye({id, index, swiper, changeViewsPopupState, section, title}) {
  return (
    <div className='absolute flex' id={id}>
        <img src={EyeIcon} alt="Oog" className='eye eye-width' title={title} onClick={() => {swiper.slideTo(index, 0); changeViewsPopupState(true, section); trackViews(index);}}/>
    </div>
  )
}
