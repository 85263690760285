import {Cookie} from '../assets/images';
import '../css/cookiePopup.css';

export default function CookiePopup({acceptAnalyticsCookies, rejectAnalyticsCookies}) {

    function closeCookiePopup() {
        document.getElementById("cookie-popup").classList.remove("show-cookie-popup");
        document.body.classList.remove('overflow-hidden');
    };

  return (
    <> 
        <div id='cookie-popup' className='cookie-popup-wrapper cookie-popup-wrapper-background cookie-popup-position'>
            <div className='flex justify-content-center'>
                <div className='cookie-message-wrapper cookie-message-wrapper-width flex column'>
                    <div className='flex'>
                        <div className='flex'>
                            <img src={Cookie} alt="Koekje" className='cookie-image'/>
                        </div>
                    </div>
                    <div className='flex column'>
                        <div className='flex justify-content-center'>
                            <p className='cookie-message cookie-message-font-size cookie-message-width'>
                                We moeten uw toestemming vragen om gebruik te mogen maken van Analytische cookies. Deze website maakt gebruik van Analytische cookies om het gebruik van de website te kunnen meten. Deze cookies verzamelen geen personengegevens en zijn dus helemaal anoniem.
                            </p>
                        </div>
                        <div className='flex justify-content-center'>
                            <button onClick={() => {acceptAnalyticsCookies(); closeCookiePopup();}} className='cursor-pointer accept-cookies-button'>
                                Akkoord
                            </button>
                        </div>
                    </div>
                    <div className='flex'>
                        <button onClick={() => {rejectAnalyticsCookies(); closeCookiePopup();}} className='cursor-pointer reject-cookies-button'>
                            <p>Weigeren</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
