import tt from '@tomtom-international/web-sdk-maps';
import { services } from '@tomtom-international/web-sdk-services';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { useRef, useEffect } from 'react';
import '../css/neighbourhood.css';

export default function Neighbourhood() {

  const mapDiv = useRef();

  useEffect(() => {
    const locatie = [4.971391651643059, 50.97863820452958];

    let map = tt.map({
      key: process.env.React_App_API_KEY_TOMTOM,
      container: mapDiv.current,
      center: locatie,
      zoom: window.innerWidth > 650 ? 14 : 13.5
    });


    let houseMarkerDIV = document.createElement('div');
    houseMarkerDIV.className = 'houseMarker';
    let houseMarker = new tt.Marker({element: houseMarkerDIV}).setLngLat([4.971391651643059, 50.97863820452958]).addTo(map);

    let shopMarker1DIV = document.createElement('div');
    shopMarker1DIV.className = 'shopMarker';
    let shopMarker1 = new tt.Marker({element: shopMarker1DIV}).setLngLat([4.956323749016011, 50.97854402054774]).addTo(map);

    let shopMarker2DIV = document.createElement('div');
    shopMarker2DIV.className = 'shopMarker';
    let shopMarker2 = new tt.Marker({element: shopMarker2DIV}).setLngLat([4.95867329185733, 50.978613817521044]).addTo(map);

    let shopMarker3DIV = document.createElement('div');
    shopMarker3DIV.className = 'shopMarker';
    let shopMarker3 = new tt.Marker({element: shopMarker3DIV}).setLngLat([4.960767147121289, 50.979550001502425]).addTo(map);

    let shopMarker4DIV = document.createElement('div');
    shopMarker4DIV.className = 'shopMarker';
    let shopMarker4 = new tt.Marker({element: shopMarker4DIV}).setLngLat([4.971815671388848, 50.974176022975804]).addTo(map);

    let shopMarker5DIV = document.createElement('div');
    shopMarker5DIV.className = 'shopMarker';
    let shopMarker5 = new tt.Marker({element: shopMarker5DIV}).setLngLat([4.9751138233116095, 50.97965478296162]).addTo(map);

    let shopMarker6DIV = document.createElement('div');
    shopMarker6DIV.className = 'shopMarker';
    let shopMarker6 = new tt.Marker({element: shopMarker6DIV}).setLngLat([4.975286187775476, 50.979769406369]).addTo(map);

    let shopMarker7DIV = document.createElement('div');
    shopMarker7DIV.className = 'shopMarker';
    let shopMarker7 = new tt.Marker({element: shopMarker7DIV}).setLngLat([4.947394496037752, 50.97879955090947]).addTo(map);

    let shopMarker8DIV = document.createElement('div');
    shopMarker8DIV.className = 'shopMarker';
    let shopMarker8 = new tt.Marker({element: shopMarker8DIV}).setLngLat([4.943546351486274, 50.96480315601284]).addTo(map);

    let schoolMarker1DIV = document.createElement('div');
    schoolMarker1DIV.className = 'schoolMarker';
    let schoolMarker1 = new tt.Marker({element: schoolMarker1DIV}).setLngLat([4.972371089468163, 50.980163180941446]).addTo(map);

    let schoolMarker2DIV = document.createElement('div');
    schoolMarker2DIV.className = 'schoolMarker';
    let schoolMarker2 = new tt.Marker({element: schoolMarker2DIV}).setLngLat([4.972914613570892, 50.97769293758001]).addTo(map);

    let schoolMarker3DIV = document.createElement('div');
    schoolMarker3DIV.className = 'schoolMarker';
    let schoolMarker3 = new tt.Marker({element: schoolMarker3DIV}).setLngLat([4.976927197931274, 50.97881427410275]).addTo(map);

    let schoolMarker5DIV = document.createElement('div');
    schoolMarker5DIV.className = 'schoolMarker';
    let schoolMarker5 = new tt.Marker({element: schoolMarker5DIV}).setLngLat([4.978246844831953, 50.98236724597288]).addTo(map);

    let schoolMarker6DIV = document.createElement('div');
    schoolMarker6DIV.className = 'schoolMarker';
    let schoolMarker6 = new tt.Marker({element: schoolMarker6DIV}).setLngLat([4.955622589720579, 50.98625089586441]).addTo(map);

    
    let schoolMarker7DIV = document.createElement('div');
    schoolMarker7DIV.className = 'schoolMarker';
    let schoolMarker7 = new tt.Marker({element: schoolMarker7DIV}).setLngLat([4.960085785158859, 50.98881729771886]).addTo(map);

    let schoolMarker8DIV = document.createElement('div');
    schoolMarker8DIV.className = 'schoolMarker';
    let schoolMarker8 = new tt.Marker({element: schoolMarker8DIV}).setLngLat([4.948770484396923, 50.965626921359764]).addTo(map);

    
    let parkingMarker1DIV = document.createElement('div');
    parkingMarker1DIV.className = 'parkingMarker';
    let parkingMarker1 = new tt.Marker({element: parkingMarker1DIV}).setLngLat([4.97503788222938, 50.97730550859516]).addTo(map);

    let parkingMarker2DIV = document.createElement('div');
    parkingMarker2DIV.className = 'parkingMarker';
    let parkingMarker2 = new tt.Marker({element: parkingMarker2DIV}).setLngLat([4.980771708187735, 50.981717091727475]).addTo(map);
    
  }, []);

  return (
    <>
        <div className='flex column neighbourhood-wrapper neighbourhood-wrapper-padding-inline'>
          <div className='neighbourhood-text-wrapper'>
            <p className='neighbourhood-text description-paragraph text-align-center'>Dat deze woonst over een toplocatie beschikt valt niet te betwisten. Zeer kindvriendelijk gelegen in een rustige straat op wandelafstand van grote winkels zoals Colruyt, Aldi, Lidl, Delhaize en Carrefour. Tevens zijn er ook verschillende scholen op wandelafstand. Aan <a className='parking-options-site-link' href="https://www.scherpenheuvel-zichem.be/plan-je-bezoek/parkeermogelijkheden">parkeermogenlijkheden</a> geen gebrek. Op amper 350m van de woonst situeert zich een openbare parking van maar liefst 415 plaatsen. Met als laatste pluspunt de topligging tenopzichte van de E314 is het plaatje compleet.</p>
          </div>
          <div ref={mapDiv} className='map'></div>
        </div>
    </>
  )
}
