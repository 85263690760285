import {Marker, HamburgerIcon, CloseIconMobileNav, IconBed, IconFloorPlan, IconToilet, IconBathroom, Frutselke} from '../assets/images';
import { useEffect } from 'react';
import '../css/header.css';

export default function Header({scrollToSection}) {

    useEffect(() => {
        const navbar = document.getElementById('navbar');
        function handleScroll() {
            if (window.scrollY > 0) {
                navbar.classList.add('navbar-box-shadow');
            } else {
                navbar.classList.remove('navbar-box-shadow');
            };
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMobileNav = () => {
        document.getElementById("mobile-nav").classList.toggle("openMobileNav")
    };

  return (
    <>
        <header id="header" className="header-wrapper flex column">
            <div id='navbar-placeholder' className='navbar-placeholder'></div>
            <div id='navbar' className='nav-wrapper flex'>
                <div className="logo-wrapper">
                    <div className='flex cursor-pointer' onClick={() => {scrollToSection("home")}}>
                        <img src={Marker} alt="logo" height="30px"/>
                        <div id='logo-text-wrapper' className='flex column'>
                            <p className='header-logo-text'>Henri Adriaensstraat 7</p>
                        </div>
                    </div>
                </div>
                <nav className='flex'>
                    <div className='flex column justify-content-center primary-navigation-desktop'>
                        <ul className="flex navbar-elements">
                            <li>
                                <p className="navbar-element-desktop" onClick={() => {scrollToSection("home")}} >Home</p>
                            </li>
                            <li>
                                <p className="navbar-element-desktop" onClick={() => {scrollToSection("ground-floor")}}>Gelijkvloers</p>
                            </li>
                            <li>
                                <p className="navbar-element-desktop" onClick={() => {scrollToSection("first-floor")}}>1ste verdiep</p>
                            </li>
                            <li>
                                <p className="navbar-element-desktop" onClick={() => {scrollToSection("second-floor")}}>2de verdiep</p>
                            </li>
                            <li>
                                <p className="navbar-element-desktop" onClick={() => {scrollToSection("contact")}}>contact</p>
                            </li>
                        </ul>
                    </div>

                    <div id='mobile-nav' className='primary-navigation-mobile primary-navigation-mobile-background'>
                        <div className='relative flex column justify-content-center full-height'>
                            <button className='close-mobile-nav-button absolute flex cursor-pointer' onClick={() => toggleMobileNav()}>
                                <img src={CloseIconMobileNav} alt="Sluit navigatie"/>
                            </button>
                            <div className='flex justify-content-center'>
                                <ul className='flex column mobile-nav-elements-gap text-align-center'>
                                    <li>
                                        <p className="navbar-element-mobile" onClick={() => {toggleMobileNav(); scrollToSection("home")}}>Home</p>
                                    </li>
                                    <li>
                                        <p className="navbar-element-mobile" onClick={() => {toggleMobileNav(); scrollToSection("ground-floor")}}>Gelijkvloers</p>
                                    </li>
                                    <li>
                                        <p className="navbar-element-mobile" onClick={() => {toggleMobileNav(); scrollToSection("first-floor")}}>1ste verdiep</p>
                                    </li>
                                    <li>
                                        <p className="navbar-element-mobile" onClick={() => {toggleMobileNav(); scrollToSection("second-floor")}}>2de verdiep</p>
                                    </li>
                                    <li>
                                        <p className="navbar-element-mobile" onClick={() => {toggleMobileNav(); scrollToSection("contact")}}>contact</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="right-nav-element flex column justify-content-center">
                    <div>
                        <button className="plan-visit-button" onClick={() => {scrollToSection("contact")}}>
                            <p>Plan een bezichtiging</p>
                        </button>
                        <button className='open-mobile-nav cursor-pointer' onClick={() => toggleMobileNav()}>
                            <img src={HamburgerIcon} alt="Hamburger icon"/>
                        </button>
                    </div>
                </div>
            </div>

            <div className="home-wrapper relative">
                <div className="header-image-wrapper"></div>
                <div className="basic-info-house-wrapper absolute">
                    <div className="flex info-house-mobile">
                        <div className="flex column price-address-house-wrapper price-address-house-wrapper-padding">
                            <p className="price price-font-size">€ 227.000</p>
                            <h2 className="address address-margin-top address-font-size">Henri Adriaansstraat 7<br/>3270 Scherpenheuvel-Zichem&nbsp;</h2>
                            <button className="plan-visit-button-wrapper-home"  onClick={() => {scrollToSection("contact")}}>
                                Plan een bezichtiging
                            </button>
                        </div>
                        <div className="characteristic-house-wrapper characteristic-house-width">
                            <div className="bedrooms flex justify-content-center">
                                <div className="flex column justify-content-center">
                                    <div className="flex justify-content-center">
                                        <img src={IconBed} alt="Slaapkamer" className='characteristic-icon'/>
                                    </div>
                                    <p className="characteristic-house-text characteristic-house-text-font-size">2 slaapkamers</p>
                                </div>
                            </div>
                            <div className="surface flex justify-content-center">
                                <div className="flex column justify-content-center">
                                    <div className="flex justify-content-center">
                                        <img src={IconFloorPlan} alt="Grondplan" className='characteristic-icon'/>
                                    </div>
                                    <p className="characteristic-house-text characteristic-house-text-font-size text-align-center">150m<sup>2</sup></p>
                                </div>
                            </div>
                            <div className="toilets flex justify-content-center">
                                <div className="flex column justify-content-center">
                                    <div className="flex justify-content-center">
                                        <img src={IconToilet} alt="Toilet" className='characteristic-icon'/>
                                    </div>
                                    <p className="characteristic-house-text characteristic-house-text-font-size">2 toiletten</p>
                                </div>
                            </div>
                            <div className="bathroom flex justify-content-center">
                                <div className="flex column justify-content-center">
                                    <div className="flex justify-content-center">
                                        <img src={IconBathroom} alt="Badkamer" className='characteristic-icon'/>
                                    </div>
                                    <p className="characteristic-house-text characteristic-house-text-font-size">1 badkamer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mail-wrapper-desktop absolute flex column justify-content-center">
                    <div className='flex justify-content-center'>
                        <a className="email-tekst" href="mailto:sander.milloen@milcode.be">
                            <p>sander.milloen@milcode.be</p>
                        </a>
                    </div>
                </div>
                <div className="frutselke-wrapper absolute">
                    <img className="frutselke" src={Frutselke} alt="frutselke"/>
                </div>
            </div>
        </header>
    </>
  )
}
