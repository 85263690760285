import {Frutselke2} from '../assets/images';
import '../css/description.css';

export default function Description() {
  return (
    <>
        <div className="tekst-wrapper tekst-wrapper-margin-top tekst-wrapper-padding flex column tekst-font-size">
            <div>
                <p className='description-paragraph'>Welkom in deze winddichte nieuwbouw casco-woning in het hartje van Scherpenheuvel. Gelegen in een rustige straat is dit blanco canvas gebouwd in 2017 de ideale basis om een meesterwerk van te maken.</p>
                <br/>
                <p className='description-paragraph'>Als we door de voordeur gaan zien we op onze linkerkant het gastentoilet. Vervolgens passeren we de doorgang naar het eerste verdiep om uit te komen op een lichtrijke leefruimte bedoeld voor de keuken. Hieraansluitend bevindt zich de absolute troef van deze woning. Een zuidelijk-georienteerd overdekt terras. Tevens is er ook de mogenlijkheid om de woning te betreden via de tuin.</p>
                <br/>
                <p className='description-paragraph'>Op het eertste verdiep bevindt zich een lichtrijke living en een ruime badkamer met een tweede toilet en aansluitingen voor een wasmachine.Tenslotte bevinden er zich twee slaapkamers op de tweede verdieping.</p>
                <br/>
                <p className='description-paragraph'>Dankzij het gebruik van hoog isolerende materialen zoals cellenbeton en 3 dubbele beglazing in combinatie met aluminium profielen en grote glaspartijen, die zorgen voor natuurlijke lichtinval, is deze casco-woning een absolute topper. Klik op de ogen op onderstaande grondplannen om binnen in een kijkje te nemen.</p>
            </div>
            <div id='frutselke-2'>
                <img className="frutselke-2" src={Frutselke2} alt="frutselke"/>
            </div>
        </div>
    </>
  )
}
