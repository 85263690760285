import '../css/viewWithoutVisualization.css';

export default function ViewWithoutVisualization({photo, altPhoto, view}) {
  return (
    <>
        <div className='absolute full-height full-width flex column justify-content-center'>
            <div className="flex justify-content-center">
                <img src={photo} alt={altPhoto} className="garden-image-width"/>
            </div>
        </div>
        <div className='flex full-height column justify-content-flex-end'>
            <div className='garden-tekst-wrapper text-align-center'>
                <p className='garden-tekst garden-tekst-font-size'>{view}</p>
            </div>
        </div>
    </>
  )
}
