import { useState, useEffect } from 'react';

import Header from './components/header';
import Footer from './components/footer';
import Description from './components/description';
import FloorPlans from './components/floorPlans';
import Neighbourhood from './components/neighbourhood';
import Contact from './components/contact';
import Views from './components/views';
import CookiePopup from './components/cookiePopup';
import {giveConsentToTrack, trackPageView, trackReachedTheBottom} from './trackingUtils.js';

function App() {
  const [viewsPopupOpen, setViewsPopupState] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [currentSection, setCurrentSection] = useState(null); // Kunnen hier miss beter current section van maken

  window.addEventListener('scroll', () => {
    if (((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
      trackReachedTheBottom();
    };
  });

  function acceptAnalyticsCookies() { // The user accepted the tracking (GDPR)
    giveConsentToTrack();
    trackPageView();
  };

  function rejectAnalyticsCookies() { // The user rejected the tracking (GDPR)
    window.localStorage.setItem("userGaveConsent", "false");
  };

  useEffect(() => { 
    const userGaveConsent = window.localStorage.getItem("userGaveConsent");
  
    if (userGaveConsent === "true") { // If the user already gave his consent in the past
      trackPageView();
    } else if (userGaveConsent === "false") { // If the user already rejected in the past
      window.fbq('consent', 'revoke');
    } else if (userGaveConsent === null) { // If it is the user's first time on the website => block the pixel and ask if the want to be tracked (GDPR)
      window.fbq('consent', 'revoke');
      setTimeout( () => { // Show the cookie popup, get consent for the analytical cookies.
        document.getElementById("cookie-popup").classList.add("show-cookie-popup");
        document.body.classList.add('overflow-hidden');
      }, 1000);
    };
  });

  function changeViewsPopupState(state, currentSection) {
    setCurrentSection(currentSection);
    setViewsPopupState(state);
    document.body.classList.toggle('overflow-hidden');
  };

  function scrollToSection(section) {
    if (section === "home") {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    } else {
        const element = document.getElementById(section);
        let position = element.getBoundingClientRect().top;
        let offset;

        if (window.matchMedia('(max-width: 900px)').matches) { //If mobile
          offset = position + window.pageYOffset - (window.innerHeight/2) + (element.offsetWidth/2);
        } else {
          offset = position + window.pageYOffset - (window.innerHeight/2) + (element.offsetHeight/2);
        };

        window.scrollTo({
            top: offset,
            behavior: 'smooth',
        });
    };
};


  return (
    <>
      <div className='overflow-x-hidden relative'>
        <div className="absolute background-image background-image-position-and-width"></div>
        <Header scrollToSection={scrollToSection}/>
        <div className="flex column">
          <Description/>
          <FloorPlans changeViewsPopupState={changeViewsPopupState} viewsPopupOpen={viewsPopupOpen} swiper={swiper}/>
          <Neighbourhood/>
          <Contact/>
        </div>
        <Views changeViewsPopupState={changeViewsPopupState} viewsPopupOpen={viewsPopupOpen} setSwiper={setSwiper} setCurrentSection={setCurrentSection} currentSection={currentSection} scrollToSection={scrollToSection}/>
        <CookiePopup acceptAnalyticsCookies={acceptAnalyticsCookies} rejectAnalyticsCookies={rejectAnalyticsCookies}/>
        <Footer/>
      </div>
    </>
  )
}

export default App;
