import { Swiper, SwiperSlide } from 'swiper/react';
import {trackViews} from '../trackingUtils.js';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import ViewWithVisualization from './viewWithVisualization';
import ViewWithoutVisualization from './viewWithoutVisualization';
import { Navigation } from 'swiper';
import {CloseIcon, GroundFloor, GroundFloorVisualization, Garden, FirtsFloorPerspective1, FirtsFloorVisualizationPerspective1, FirtsFloorPerspective2, FirtsFloorVisualizationPerspective2, BathroomPerspective1, BathroomVisualizationPerspective1, BathroomPerspective2, BathroomVisualizationPerspective2, MasterBedroomPerspective1, MasterBedroomVisualizationPerspective1, MasterBedroomPerspective2, MasterBedroomVisualizationPerspective2, childrensRoomPerspective1, childrensRoomVisualizationPerspective1, childrensRoomPerspective2, childrensRoomVisualizationPerspective2, childrensRoomPerspective3, childrensRoomVisualizationPerspective3} from '../assets/images';
import '../css/views.css';

export default function Views({changeViewsPopupState, viewsPopupOpen, setSwiper, setCurrentSection, currentSection, scrollToSection}) {

  function checkIfWentToOtherSection(index) {
    if (currentSection === null) { //if the user has just opened the views popup
      return;
    };

    if (currentSection === "ground-floor") {
      if (index > 1) {
        setCurrentSection("first-floor");
        scrollToSection("first-floor");
      };
    } else if (currentSection === "first-floor") {
      if (index < 2) { 
        setCurrentSection("ground-floor");
        scrollToSection("ground-floor");
      } else if (index > 5) {
        setCurrentSection("second-floor");
        scrollToSection("second-floor");
      };
    } else if (currentSection === "second-floor") {
      if (index < 6) {
        setCurrentSection("first-floor");
        scrollToSection("first-floor");
      };
    }; 
  };

  return (
    <>
        <div className={`overlay-hidden ${viewsPopupOpen ? "show-overlay" : ""}`}>
          <div className='flex column views-wrapper'>
            <div className={`close-button-hidden flex ${viewsPopupOpen ? "close-button-wrapper close-button-wrapper-position" : ""}`}>
              <img src={CloseIcon} className="close-button cursor-pointer" alt="Sluitings knop" onClick={() => changeViewsPopupState(false, null)} />
            </div>
            <div className='flex justify-content-center overlay-background flex-grow'>
              <div className='slider-wrapper flex'>
                <Swiper
                  modules={[Navigation]}
                  navigation
                  speed={1100}
                  slidesPerView={1}
                  onSlideChange={(e) => {checkIfWentToOtherSection(e.realIndex); trackViews(e.realIndex)}}
                  className="myswiper flex"
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide className="slide-inactive">
                    <ViewWithoutVisualization photo={Garden} altPhoto={"Terras"} view={"Overdekt Terras"}/>
                  </SwiperSlide>
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={GroundFloor} altPhoto={"Gelijkvloers"} visualization={GroundFloorVisualization} altVisualization={"Gelijkvloers visualisatie"} id={'ground-floor-casco'} view={"Eetruimte - Keuken"}/>
                  </SwiperSlide>
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={FirtsFloorPerspective1} altPhoto={"Living aanzicht 1"} visualization={FirtsFloorVisualizationPerspective1} altVisualization={"Living visualisatie aanzicht 1"} id={'first-floor-casco-perspective-1'} view={"Living"}/>
                  </SwiperSlide>
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={FirtsFloorPerspective2} altPhoto={"Living aanzicht 2"} visualization={FirtsFloorVisualizationPerspective2} altVisualization={"Living visualisatie aanzicht 2"} id={'first-floor-casco-perspective-2'} view={"Living"}/>
                  </SwiperSlide>
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={BathroomPerspective1} altPhoto={"Badkamer aanzicht 1"} visualization={BathroomVisualizationPerspective1} altVisualization={"Badkamer visualisatie aanzicht 1"} id={'bathroom-casco-perspective-1'} view={"Badkamer"}/>
                  </SwiperSlide>
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={BathroomPerspective2} altPhoto={"Badkamer aanzicht 2"} visualization={BathroomVisualizationPerspective2} altVisualization={"Badkamer visualisatie aanzicht 2"} id={'bathroom-casco-perspective-2'} view={"Badkamer"}/>
                  </SwiperSlide> 
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={MasterBedroomPerspective1} altPhoto={"Grote slaapkamer aanzicht 1"} visualization={MasterBedroomVisualizationPerspective1} altVisualization={"Grote slaapkamer visualisatie aanzicht 1"} id={'master-bedroom-casco-perspective-1'} view={"Grote slaapkamer"}/>
                  </SwiperSlide> 
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={MasterBedroomPerspective2} altPhoto={"Grote slaapkamer aanzicht 2"} visualization={MasterBedroomVisualizationPerspective2} altVisualization={"Grote slaapkamer visualisatie aanzicht 2"} id={'master-bedroom-casco-perspective-2'} view={"Grote slaapkamer"}/>
                  </SwiperSlide> 
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={childrensRoomPerspective1} altPhoto={"Kinderkamer aanzicht 1"} visualization={childrensRoomVisualizationPerspective1} altVisualization={"Kinder kamer visualisatie aanzicht 1"} id={'childerens-room-casco-perspective-1'} view={"Kinderkamer"}/>
                  </SwiperSlide> 
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={childrensRoomPerspective2} altPhoto={"Kinderkamer aanzicht 2"} visualization={childrensRoomVisualizationPerspective2} altVisualization={"Kinder kamer visualisatie aanzicht 2"} id={'childerens-room-casco-perspective-2'} view={"Kinderkamer"}/>
                  </SwiperSlide> 
                  <SwiperSlide className="slide-inactive">
                    <ViewWithVisualization photo={childrensRoomPerspective3} altPhoto={"Kinderkamer aanzicht 3"} visualization={childrensRoomVisualizationPerspective3} altVisualization={"Kinder kamer visualisatie aanzicht 3"} id={'childerens-room-casco-perspective-3'} view={"Kinderkamer"}/>
                  </SwiperSlide> 
                </Swiper>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
